<!-- man-edit -->
<template>
  <el-dialog
    class="man-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="man-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="用户名:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <!-- <el-form-item v-if="!this.isEdit" label="密码:" prop="pwd">
        <el-input
          v-model.trim="model.pwd"
          placeholder="请输入"
          clearable
          show-password
        />
      </el-form-item> -->

      <el-form-item label="角色:" prop="roleId">
        <role v-model="model.roleId" />
      </el-form-item>

      <el-form-item label="分组:" prop="groupId">
        <group v-model="model.groupId" check-strictly />
      </el-form-item>

      <el-form-item label="手机号:" prop="telephone">
        <el-input
          v-model.trim="model.telephone"
          placeholder="请输入"
          clearable
        />
      </el-form-item>

      <el-form-item class="from-avatar" label="头像:" prop="avatar">
        <div class="form-avatar">
          <choose-file
            :extensions="avatarFileExtensions"
            :size="avatarFileSize"
            @on-mime-invalid="handleAvatarFileTypeInvalid"
            @on-size-exceeded="handleAvatarFileSizeExceeded"
            @on-file-choose="handleAvatarFileChoose"
          >
            <el-avatar class="avatar-img" :src="avatarImg" />
          </choose-file>

          <el-input class="avatar-input" v-model="model.avatar" />

          <div class="avatar-file-err">{{ avatarFileErrorMsg }}</div>
        </div>
      </el-form-item>

      <el-form-item label="状态:" prop="status">
        <el-radio-group v-model="model.status">
          <el-radio
            v-for="item in userStatusList"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>

    <avatar-cropper
      v-model="showCropperDialog"
      :image="avatarFile"
      :width="60"
      @on-close="handleCropperClose"
      @on-ok="handleCropperOkClick"
    />

    <template v-slot:footer>
      <div class="man-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
        >
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Role from '../../../../../components/role';
  import Group from '../../../../../components/group';
  import ChooseFile from '../../../../../components/choose-file';
  import avatarPlaceholder from '../../../../../assets/img/avatar-placeholder.png';
  import userStatus from '../../../../../enum/user-status';
  import { isTelephoneString } from '../../../../../util/validator';
  // import { md5Encode } from '../../../../../util/encrypt';
  import { getUser, addUser, updateUser } from '../../../../../api/auth/user';

  // 校验手机号
  const telephoneValidator = (rule, value, callback) => {
    if (!value) callback();

    isTelephoneString(value)
      ? callback()
      : callback(new Error('请填写正确的手机号码'));
  };

  export default {
    name: 'man-edit',
    components: {
      Role,
      Group,
      ChooseFile,
      AvatarCropper: () =>
        import(
          /* webpackChunkName: 'man-edit-cropper' */ '../../../../../components/image-cropper'
        ),
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 用户 id
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        // 当前编辑的用户对象
        user: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          // pwd: [{ required: true, message: '请填写', trigger: 'change' }],
          roleId: [{ required: true, message: '请选择', trigger: 'change' }],
          groupId: [{ required: true, message: '请选择', trigger: 'change' }],
          telephone: [
            { required: true, message: '请填写', trigger: 'change' },
            { validator: telephoneValidator, trigger: 'blur' },
          ],
          status: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        // 头像图片文件
        avatarFile: '',
        avatarFileExtensions: ['png', 'jpg', 'jpeg'],
        avatarFileSize: 300 * 1024,
        // 头像图片错误文案
        avatarFileErrorMsg: '',
        // 是否显示裁切图片对话框
        showCropperDialog: false,
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState(['userInfo']),
      ...mapState('auth/user', ['groupId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.id;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}用户`;
      },
      // 用户状态列表
      userStatusList() {
        const { map } = userStatus;

        return (
          Object.entries(map)
            .map(([value, key]) => ({
              label: key.replace('已', ''),
              value: +value,
            }))
            // 过滤掉'待确认'状态
            .filter(item => item.value !== userStatus.enum.waiting)
        );
      },
      // 用户头像图片地址
      avatarImg() {
        return this.model.avatar || avatarPlaceholder;
      },
      // 当前登录用户的 id
      currUserId() {
        const { id = '' } = this.userInfo || {};

        return id || '';
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      ...mapActions(['actUserInfo']),

      // 初始化模型
      initModel(data = {}) {
        const {
          name = '',
          // pwd = '',
          roleId = '',
          groupId = this.groupId,
          telephone = '',
          avatar = '',
          status = userStatus.enum.enabled,
        } = data || {};

        this.model = {
          name,
          // pwd,
          roleId,
          groupId,
          telephone,
          avatar,
          status,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          // pwd: this.isEdit ? undefined : md5Encode(pwd),
          id: this.id,
        };
        const method = this.isEdit ? updateUser : addUser;
        const res = await method(params);

        return !!res;
      },
      // 获取用户对象详情
      async getUser() {
        const res = await getUser(this.id);

        if (!res) return false;

        this.user = res.data;

        return true;
      },
      // 头像文件 mime 无效
      handleAvatarFileTypeInvalid() {
        this.avatarFileErrorMsg = `请选择 ${this.avatarFileExtensions.join(
          '、'
        )} 类型的图片`;
      },
      // 头像文件大小超出
      handleAvatarFileSizeExceeded() {
        this.avatarFileErrorMsg = `请选择大小不超过 ${
          this.avatarFileSize / 1024
        }KB 的图片`;
      },
      // 头像文件选取完成
      handleAvatarFileChoose(files) {
        this.avatarFileErrorMsg = '';

        const avatarFile = files[0];

        if (!avatarFile) return;

        this.avatarFile = avatarFile;
        this.showCropperDialog = true;
      },
      // 图片裁切对话框取消按钮单击
      handleCropperClose() {
        this.avatarFile = '';
        this.showCropperDialog = false;
      },
      // 图片裁切对话框确定按钮单击
      handleCropperOkClick({ base64 }) {
        this.model.avatar = base64;
        this.showCropperDialog = false;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getUser();

        this.loading = false;

        if (!success) return;

        this.initModel(this.user);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.user = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');

        // 如果所修改的用户是当前登录用户自身，则更新当前登录用户数据
        this.id === this.currUserId && this.actUserInfo();
      },
    },
    watch: {
      groupId(n) {
        this.model.groupId = n;
      },
    },
  };
</script>

<style scoped lang="scss">
  .man-edit {
    .man-edit-form {
      .el-select,
      .el-input {
        width: 100%;
      }

      .form-avatar {
        display: flex;
        position: relative;

        .avatar-img {
          position: relative;
          cursor: pointer;

          &::before {
            content: '+';
            position: absolute;
            left: 50%;
            z-index: 2;
            margin-left: -8px;
            font-size: 26px;
            color: transparent;
          }

          &::after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: transparent;
          }

          &:hover {
            &::before {
              color: #fff;
            }

            &::after {
              background-color: rgba(0, 0, 0, 0.5);
            }
          }
        }

        .avatar-input {
          display: none;
        }

        .avatar-file-err {
          position: absolute;
          bottom: -24px;
          left: 0;
          font-size: 12px;
          color: #f56c6c;
        }
      }
    }
  }
</style>
